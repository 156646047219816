import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Torn is a laser tag tournament managment software.</h1>
        <h2>
          Compatible with Ozone, Helios Legacy, Nexus, and Laserforce laser tag
          systems
        </h2>
        <h3>Written by AJ Horsman & Doug Burbidge</h3>
        <h2>Latest version available for download here</h2>
        <h2>
          <a href="https://torn.lasersports.au/downloads/latest">Torn 5</a>
        </h2>
      </header>
    </div>
  );
}

export default App;
